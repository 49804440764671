import React from 'react'
import Fade from '../../helpers/fade'
import Api from '../../helpers/api'
import moment from 'moment'
import {Link} from 'react-router-dom'
import Paginator from '../../helpers/paginator'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getSearchResults} from '../../actions'
import SearchBox from './SearchBox'
import IVWPixel from '../../helpers/ivw'
import {cdn} from '../../helpers/config'
import Head from '../Layout/Head'
import {initialize} from "../../helpers/agf";

const itemsPerPage = 20

class Search extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: true
        }

        this.paginateSearchResults = this.paginateSearchResults.bind(this)
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.props.getSearchResults(this.props.location.state)
        }
        IVWPixel.pageLoad()
        initialize('_search', 'search');
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.location.state !== nextProps.location.state) {
            Api.post('search', {
                edition: nextProps.location.state.edition,
                pattern: nextProps.location.state.pattern,
                startDate: nextProps.location.state.startDate,
                count: true,
            }).then((response) => {
                this.setState({count: response.data.count})
                this.props.getSearchResults(nextProps.location.state)
            })
            IVWPixel.pageLoad()
        }
    }

    paginateSearchResults(pageNumber) {
        localStorage.setItem('page_number', pageNumber);
        this.props.getSearchResults({
            edition: this.props.location.state.edition,
            page: pageNumber,
            pattern: this.props.location.state.pattern,
            startDate: this.props.location.state.startDate
        })
    }

    render () {
        const canonicalUrl = window.location.href;

        return (
            <Fade>
                <Head title="Traueranzeigen suchen" canonicalUrl={canonicalUrl} />
                <div className="uk-container uk-padding">
                    <div className="divider">
                        <h3 className="nowrap">Traueranzeigen suchen</h3>
                    </div>

                    <SearchBox
                        hideHeadline={true}
                        hideDivider={true}
                        pattern={(typeof this.props.location.state === 'undefined') ? '' : this.props.location.state.pattern}
                        edition={(typeof this.props.location.state === 'undefined') ? 'all' : this.props.location.state.edition}
                        date={(typeof this.props.location.state === 'undefined') ? '' : this.props.location.state.startDate}
                    />
                    {!this.props.searchResults.loading ?
                        (
                            <div id="searchResults">
                                {this.props.searchResults.success ? (
                                    <>
                                        {(this.props.searchResults.notices.total > 0) ? (
                                            <ul className="uk-list uk-list-striped">
                                                {typeof this.props.searchResults.notices !== 'undefined' && this.props.searchResults.notices.data.map((notice, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                to={'/traueranzeige-search/' + notice.vname + '-' + notice.nname + '/' + notice.id}
                                                                className="uk-flex">
                                                                <figure className="uk-margin-small-right"
                                                                        style={{marginBottom: 0}}>
                                                                    <img
                                                                        src={cdn + notice.id + '&width=150'}
                                                                        title={notice.vname + ' ' + notice.nname + ' - Traueranzeige'}
                                                                        alt={notice.vname + ' ' + notice.nname + ' - Traueranzeige'}
                                                                    />
                                                                </figure>
                                                                <div className="description">
                                                                    <h3 className="name">{notice.vname} {notice.nname}</h3>
                                                                    <div
                                                                        className="date uk-color-gold">{moment(notice.sterbetag).format('L')}
                                                                        {notice.wohnort.length > 0 &&
                                                                        <>
                                                                            &nbsp;&middot; {notice.wohnort}
                                                                        </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        ) : (
                                            <div className="uk-text-center uk-margin-medium-top">Es konnte keine
                                                Traueranzeige mit Ihren gewählten Suchanfragen gefunden
                                                werden.</div>
                                        )}
                                    </>
                                ) : (
                                    <span/>
                                )}
                            </div>

                        ) : (
                            <div className="loading">
                                <div data-uk-spinner={''}/>
                                <div>Lade Daten...</div>
                            </div>
                        )}

                    {typeof this.props.searchResults.notices !== 'undefined' &&
                    <Paginator
                        itemsPerPage={itemsPerPage}
                        totalItems={this.props.searchResults.notices.total}
                        onPageChange={this.paginateSearchResults}
                        activePage={1}
                    />
                    }
                </div>
                <div className="uk-margin-medium-bottom"/>
            </Fade>
        )
    }
}

function mapStateToProps(state) {
    return {
        searchResults: state.searchResults,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getSearchResults: getSearchResults,

    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Search)