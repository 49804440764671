import Api from '../helpers/api';
import React from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import Fade from '../helpers/fade';
import {getDeathNoticeEdition} from '../actions';
import moment from 'moment';

import styles from '../styles/app.scss';
import DeathNotice from "./DeathNotice";
import IVWPixel from "../helpers/ivw";
import Head from "./Layout/Head";
import {getAssetIdFromPath, initialize} from "../helpers/agf";

class DeathNoticeOverview extends React.Component {

    state = {
        edition: this.props.match.params.edition || 'all',
        date: moment().format('YYYY-MM-DD')
    };


    /**
     * handles changes of property edition (refer to Main)
     *
     * @param nextProps
     * @param prevState
     * @returns {*}
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.edition === 'undefined' || typeof nextProps.match.params.edition === 'undefined') {
            nextProps.match.params.edition = 'all';
        }

        if (nextProps.match.params.edition !== prevState.edition) {
            return {
                edition: nextProps.match.params.edition || 'all',
            };
        }
        return null;
    }

    /**
     * get death notices on change of edition property
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.edition === 'undefined' || typeof prevProps.match.params.edition === 'undefined') {
            prevProps.match.params.edition = 'all';

            let assetId = getAssetIdFromPath('/anzeigen/alle Traueranzeigen');
            initialize(assetId, 'anzeigen');
        }

        if (prevProps.match.params.edition !== this.state.edition) {
            let edition = this.props.editions.data.filter((x) => {
                return x.link === this.state.edition
            })[0] || {id: 0, bezeichnung: 'alle Traueranzeigen', link: 'all'};
            this.props.getDeathNoticeEdition({editionId: edition.id || 0, date: this.state.date}, false);

            let assetId = getAssetIdFromPath('/anzeigen/' + edition.bezeichnung);
            initialize(assetId, 'anzeigen');
        }
        if (localStorage.getItem('deathNoticeDate') !== moment().format('YYYY-MM-DD') &&
            document.getElementById('todayButton') === null) {
            createLatestButton(prevProps)
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        /** workaround for async promise is not completed when component is mounted */
        if (typeof this.props.editions.edition === 'undefined') {
            Api.post('get-editions').then((response) => {
                let edition = response.data.editions.filter((x) => {
                    return x.link === this.state.edition
                })[0] || {id: 0, bezeichnung: 'alle Traueranzeigen', link: 'all'};
                this.props.getDeathNoticeEdition({editionId: edition.id || 0, date: this.state.date}, true);

                let assetId = getAssetIdFromPath('/anzeigen/' + edition.bezeichnung);
                initialize(assetId, 'anzeigen');
            })
        } else {
            let assetId = getAssetIdFromPath('/anzeigen/' + this.props.editions.edition);
            initialize(assetId, 'anzeigen');
        }

        IVWPixel.pageLoad();
    }


    /**
     * render method
     *
     * @returns {*}
     */
    render() {
        let editionData = this.props.editions.data.filter((x) => {
            return x.link === this.state.edition
        });
        const edition = (editionData.length > 0) ? editionData[0] : {
            id: 0,
            bezeichnung: 'alle Traueranzeigen',
            link: 'all'
        };
        const canonicalUrl = window.location.href;

        return (
            <Fade>
                <Head title={"PNP Trauerportal - " + edition.bezeichnung} canonicalUrl={canonicalUrl} />
                <div className={styles["death-notice-overview"]}>
                    <div className={[styles["banner"], styles["uk-padding-large"]].join(' ')}
                         data-uk-parallax="bgy: -100">
                        <div
                            className={[styles["uk-container"], styles["uk-padding"], styles["uk-text-center"]].join(' ')}>
                            <h1>Traueranzeigen</h1>
                            <div className={[styles["uk-color-gold"], styles["uk-text-italic"]].join(' ')}>
                                {edition.bezeichnung} vom {moment(this.props.editionNotices.date).format('L')}
                            </div>
                        </div>
                    </div>
                    {(typeof this.props.editionNotices.editionNotices !== 'undefined' && !this.props.editionNotices.loading && !this.props.editions.loading) ? (

                        <div className={styles["current-death-notices"]}>
                            <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>
                                <Fade>
                                    <div className={[styles["uk-flex"], styles["uk-flex-between"]].join(' ')}>
                                        <div className="buttonContainer">
                                            <div className={styles["uk-width-1-2"]}>
                                                {(this.props.editionNotices.prev !== null) &&
                                                <button
                                                    className={[styles["uk-button"], styles["uk-button-primary"]].join(' ')}
                                                    onClick={() => this.props.getDeathNoticeEdition({
                                                        editionId: edition.id,
                                                        date: moment(this.props.editionNotices.prev.sterbetag).format('YYYY-MM-DD')
                                                    }, false)}
                                                >
                                                    &laquo; {moment(this.props.editionNotices.prev.sterbetag).format('L')}
                                                </button>
                                                }
                                            </div>
                                            <div className="latestContainer"></div>
                                            <div
                                                className={[styles["uk-width-1-2"], styles["uk-text-right"]].join(' ')}>
                                                {(this.props.editionNotices.next !== null) &&
                                                <button
                                                    className={[styles["uk-button"], styles["uk-button-primary"]].join(' ')}
                                                    onClick={() => this.props.getDeathNoticeEdition({
                                                        editionId: edition.id,
                                                        date: moment(this.props.editionNotices.next.sterbetag).format('YYYY-MM-DD')
                                                    }, false)}
                                                >
                                                    {moment(this.props.editionNotices.next.sterbetag).format('L')} &raquo;
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    {(this.props.editionNotices.editionNotices.length > 0) ? (
                                        <div
                                            className={[styles["uk-grid-match"], styles["uk-margin-large-top"]].join(' ')}
                                            data-uk-grid>
                                            {this.props.editionNotices.editionNotices.map((notice, key) => {
                                                return (
                                                    <DeathNotice notice={notice} key={key}/>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <div
                                            className={[styles["uk-text-center"], styles["uk-padding"], styles["uk-margin-large-bottom"]].join(' ')}>
                                            Für den {moment(this.state.date).format('L')} sind keine Traueranzeigen
                                            vorhanden.
                                        </div>
                                    )}


                                </Fade>

                            </div>

                        </div>

                    ) : (
                        <div className={styles["uk-position-relative"]} style={{height: '20rem'}}>
                            <div className={[styles["uk-position-center"], styles["loading"]].join(' ')}>
                                <div data-uk-spinner={''}/>
                                <div>Lade Daten...</div>
                            </div>
                        </div>
                    )}
                </div>

            </Fade>
        )

    }

}

function createLatestButton(prevProps) {
    var element = document.createElement("input");
    //Assign different attributes to the button.
    element.type = 'button';
    element.value = 'Aktuellste';
    element.name = 'todayButton';
    element.id = 'todayButton'
    element.classList.add('uk-button', 'uk-button-primary')
    element.onclick = function () {
        prevProps.getDeathNoticeEdition({
            editionId: prevProps.editionNotices.editionId,
            date: moment().format('YYYY-MM-DD')
        }, false)
    };
    const elementContainer = document.getElementsByClassName('current-death-notices')[0]
    if (elementContainer !== undefined) {
        const buttonContainer = elementContainer.getElementsByClassName('latestContainer')[0]
        buttonContainer.appendChild(element);
    }
}

function mapStateToProps(state) {
    return {
        editions: state.editions,
        editionNotices: state.editionNotices,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getDeathNoticeEdition,
    }, dispatch)
}


export default connect(mapStateToProps, matchDispatchToProps)(DeathNoticeOverview);
