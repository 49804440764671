import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    getNewCondolences,
    getNewCommemorations,
    getDeathNoticeDetails,
    getDeathNoticeCondolences,
} from '../actions';
import Fade from '../helpers/fade';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from '../styles/app.scss';
import Sidebar from "./Sidebar";
import CondolenceBook from "./CondolenceBook/CondolenceBook";
import styler from "../helpers/styler";
import IVWPixel from "../helpers/ivw";
import {appName, baseUrl, cdn, imgWidth} from '../helpers/config';
import Head from "./Layout/Head";
import {initialize} from "../helpers/agf";

/**
 * Detail View of Death Notice
 *
 * @class DeathNotice
 * @author Alexander Jungwirth
 */
class DeathNoticeDetails extends Component {

    // flag used to stop spamming of the death notice checks for each render update
    checkForDeathNotice = true;

    state = {
        id: this.props.match.params.id || 0,
        show_login_form: false,
        showNoticeText: true,
        source: this.props.location.pathname.startsWith('/traueranzeige-search')
            ? 'traueranzeige-search'
            : 'traueranzeige',
    };


    /**
     * componentDidMount
     */
    componentDidMount() {
        this.props.getDeathNoticeDetails(this.state.id);
        this.props.getNewCondolences();
        IVWPixel.pageLoad();
        initialize(this.state.id, 'traueranzeige');
        this.checkPrevNextDeathNotices();
    }

    /**
     * change state when url changed
     * @param nextProps
     * @param prevState
     * @returns {*}
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.id !== prevState.id) {
            return {id: nextProps.match.params.id}
        } else {
            return null;
        }
    }

    /**
     * get details when state changed
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.id !== this.state.id) {
            this.props.getDeathNoticeDetails(this.state.id);
            IVWPixel.pageLoad();
            initialize(this.state.id, 'traueranzeige');
        }
        let name = '';
        if (this.props.deathNoticeDetails.details !== undefined) {
            name = this.props.deathNoticeDetails.details.vname + '-' + this.props.deathNoticeDetails.details.nname
            name = name.replace(/\s+/g, '');
        }
        if (this.checkForDeathNotice && name !== '' && name !== 'undefined-undefined') {
            if (this.props.deathNoticeDetails.details.status === 2) {
                this.props.history.push('/traueranzeige/' + name + '/' + this.props.deathNoticeDetails.details.id)
            }
            else {
                this.props.history.push('/traueranzeige/' + this.props.deathNoticeDetails.details.id)
            }
        }
        this.checkPrevNextDeathNotices();
    }

    /**
     * show modal with enlarged image
     */
    enlargeImage = () => {
        const currentWidth = window.innerWidth;
        const imageUrl = cdn + this.state.id + '&width=' + (currentWidth - 80);
        this.setState({enlargedImage: imageUrl});
        document.addEventListener("keydown", this.closeOnEsc, false);
    };

    /**
     * event handler for ESC key
     *
     * @param event
     */
    closeOnEsc = (event) => {
        if (event.keyCode === 27) {
            this.setState({enlargedImage: null});
            document.removeEventListener("keydown", this.closeOnEsc, false);
        }
    };

    /**
     * fill banner with death notice details
     *
     * @returns {*}
     */
    bannerDetails = () => {
        if (typeof this.props.deathNoticeDetails.details !== 'undefined' && this.props.deathNoticeDetails.details.status === 2) {

            return (
                <>
                    <h1>{this.props.deathNoticeDetails.details.vname} {this.props.deathNoticeDetails.details.nname}</h1>
                    <div className={styles["uk-text-italic"]}>
                        {typeof this.props.deathNoticeDetails.details.wohnort !== 'undefined' && this.props.deathNoticeDetails.details.wohnort.length > 0 &&
                        <>{this.props.deathNoticeDetails.details.wohnort} &middot; </>
                        }
                        {typeof this.props.deathNoticeDetails.details.sterbetag !== 'undefined' &&
                        <>erschienen am: {moment(this.props.deathNoticeDetails.details.sterbetag).format('L')}</>
                        }
                    </div>
                    <div className={styles["uk-text-italic"]}>
                        {(this.props.deathNoticeDetails.details.todestag != null) ?
                            (<>verstorben
                                am: {moment(this.props.deathNoticeDetails.details.todestag).format('L')}</>) : ''
                        }
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <h1>&nbsp;</h1>
                    <div className={styles["uk-text-italic"]}/>
                </>
            )
        }
    };

    toggleNoticeText = () => {
        this.setState({
            showNoticeText: !this.state.showNoticeText
        })
    };

    /**
     * checks the next (two) and previous (two) death notices based on the id of the current death notice
     */
    checkPrevNextDeathNotices = () => {
        if (this.checkForDeathNotice && this.props.deathNoticeDetails.id_after && this.props.deathNoticeDetails.id_before) {
            if (this.props.deathNoticeDetails.id_after !== 'not_found') {
                this.updatePrevNextButton('nextButton', this.props.deathNoticeDetails.id_after)
            } else {
                if (document.getElementById('nextButton') !== null) {
                    const button = document.getElementById('nextButton')
                    button.parentElement.removeChild(button)
                }
            }
            if (this.props.deathNoticeDetails.id_before !== 'not_found') {
                this.updatePrevNextButton('prevButton', this.props.deathNoticeDetails.id_before)
            } else {
                if (document.getElementById('prevButton') !== null) {
                    const button = document.getElementById('prevButton')
                    button.parentElement.removeChild(button)
                }
            }
            this.checkForDeathNotice = false
        }
    }

    /**
     Destroys existing buttons and creates new one
     @param label determines next or prev button
     @param id determines how many death notices are skipped
     */
    updatePrevNextButton(label, id) {
        if (document.getElementById(label) === null) {
            this.createAndInsertButton(id)
        } else {
            const button = document.getElementById(label)
            button.parentElement.removeChild(button)
            this.createAndInsertButton(id)
        }
    }

    /**
     Changes current death notice and renders new one
     @param prevNextId determines how many death notices are skipped
     */
    changeDeathNotice = (prevNextId) => {
        this.disablePrevNextButton()
        if (!this.checkForDeathNotice) {
            this.props.getDeathNoticeDetails(prevNextId)
            this.checkForDeathNotice = true;
        }
    }

    /**
     Disables the button bc otherwise the change death notice function can be fired all the time
     */
    disablePrevNextButton = () => {
        if (document.getElementById('prevButton') !== null) {
            document.getElementById('prevButton').disabled = true;
        }
        if (document.getElementById('nextButton') !== null) {
            document.getElementById('nextButton').disabled = true;
        }
    }

    /**
     Creates a button with certain attributes and which changes death notices
     @param prevNextId: determines how many death notices are skipped
     */
    createAndInsertButton = (prevNextId) => {
        const buttonContainer = document.getElementsByClassName('prevNextContainer')[0]
        const button = document.createElement("input");
        button.type = 'button';
        button.classList.add('uk-button', 'uk-button-primary')
        button.onclick = () => this.changeDeathNotice(prevNextId);
        button.disabled = false;
        if (this.props.deathNoticeDetails.details.id < prevNextId) {
            button.value = '⇩';
            button.name = 'nextButton';
            button.id = 'nextButton';
        } else {
            button.value = '⇩';
            button.name = 'prevButton';
            button.id = 'prevButton';
        }
        buttonContainer.appendChild(button)
    }

    noticeDetails = () => {
        if (typeof this.props.deathNoticeDetails.loading !== 'undefined' && !this.props.deathNoticeDetails.loading) {
            if (this.props.deathNoticeDetails.success) {
                if (this.props.deathNoticeDetails.details.status === 2) {
                    return (
                        <>
                            <Head
                                title={this.props.deathNoticeDetails.details.vname + ' ' + this.props.deathNoticeDetails.details.nname + ' - PNP Trauerportal'}
                                description={`Anzeige zu ${this.props.deathNoticeDetails.details.vname} ${this.props.deathNoticeDetails.details.nname} im ${appName}. Nachrufe, Danksagungen, Kondolenzbuch und Todesanzeigen....`}>
                                <title>{this.props.deathNoticeDetails.details.vname + ' ' + this.props.deathNoticeDetails.details.nname}</title>
                                <meta name="robots" content="index, follow"/>
                                {!this.props.match.params.name &&
                                <link rel="canonical"
                                      href={`${baseUrl}traueranzeige/${this.props.deathNoticeDetails.details.vname}-${this.props.deathNoticeDetails.details.nname}/${this.state.id}`}/>
                                }
                            </Head>
                            <article className={styles["detail"]}>
                                <div className={[styles["uk-container"], styles["uk-text-center"]].join(' ')}>
                                    <div>
                                        <figure className={styles["dn-container"]}>
                                            <img
                                                src={cdn + this.props.deathNoticeDetails.details.id + '&width=' + imgWidth}
                                                onClick={this.enlargeImage.bind(this)}
                                                title={this.props.deathNoticeDetails.details.vname + ' ' + this.props.deathNoticeDetails.details.nname + ' - Traueranzeige'}
                                                alt={this.props.deathNoticeDetails.details.vname + ' ' + this.props.deathNoticeDetails.details.nname + ' - Traueranzeige'}
                                            />
                                        </figure>
                                    </div>
                                </div>
                            </article>

                            {!this.props.deathNoticeDetails.hideCondolenceBook &&
                            <div id="anchor"
                                 className={[styles['condolences'], styles['uk-margin-small-top']].join(' ')}>
                                <CondolenceBook condolenceId={this.props.deathNoticeDetails.details.id}/>
                            </div>
                            }
                        </>
                    )
                } else {
                    return (
                        <div
                            className={styler(["uk-margin-large", "uk-text-bold", "uk-text-center"])}>Diese Anzeige ist nicht verfügbar</div>
                    )
                }

            } else {
                return (
                    <div
                        className={styler(["uk-margin-large", "uk-text-bold", "uk-text-center"])}>{this.props.deathNoticeDetails.message}</div>
                )
            }


        } else if (!this.props.deathNoticeDetails.success) {
            return (<div className={styles["loading"]}>
                <div data-uk-spinner={''}/>
                <div>Lade Daten...</div>
            </div>)
        }


    };

    handleBackToList = () => {
        this.props.history.push('/search');
    };

    /**
     * render method
     *
     * @returns {*}
     */
    render() {
        return (
            <div>

                <div className={styles["death-notice"]}>
                    <div className={styles["notice-banner"]} data-uk-parallax="bgy: -100">
                        <div
                            className={[styles["uk-container"], styles["uk-padding"], styles["uk-text-center"]].join(' ')}>
                            {this.bannerDetails()}
                        </div>
                    </div>

                    <div
                        className={[styles["uk-container"], styles["uk-margin-medium-top"], styles["uk-margin-medium-bottom"]].join(' ')}>
                        <div className={styles["uk-grid-medium"]} data-uk-grid>
                            <div
                                className={[styles["details"], styles["uk-width-1-1"], styles["uk-width-2-3@m"]].join(' ')}>
                                {this.state.source === 'traueranzeige-search' && (
                                    <div className="uk-container uk-margin-medium-bottom">
                                        <button
                                            className={[styles["uk-button"], styles["uk-button-primary"], styles["uk-width-1-1@s"], styles["uk-width-1-3@m"]].join(' ')}
                                            onClick={this.handleBackToList}>
                                            Zurück zur Trefferliste
                                        </button>
                                    </div>
                                )}
                                {this.noticeDetails()}
                            </div>

                            <Sidebar/>

                        </div>
                    </div>
                </div>
                <div className={`prevNextContainer ${this.state.source === 'traueranzeige' ? '' : 'invisible'}`}></div>
                {this.state.enlargedImage &&
                <Fade>
                    <div className={styles["modal"]}>
                        <div className={styles["closer"]} onClick={() => {
                            this.setState({enlargedImage: null})
                        }}><FontAwesomeIcon icon="times-circle" size="2x"/></div>
                        <img
                            className={styles["inner"]}
                            src={this.state.enlargedImage}
                            title={this.props.deathNoticeDetails.details.vname + ' ' + this.props.deathNoticeDetails.details.nname + ' - Traueranzeige'}
                            alt={this.props.deathNoticeDetails.details.vname + ' ' + this.props.deathNoticeDetails.details.nname + ' - Traueranzeige'}
                        />
                    </div>
                </Fade>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        deathNoticeDetails: state.deathNoticeDetails,
        deathNoticeCondolences: state.deathNoticeCondolences,
        newCondolences: state.newCondolences,
        newCommomerations: state.newCommomerations,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getDeathNoticeDetails: getDeathNoticeDetails,
        getNewCondolences: getNewCondolences,
        getNewCommemorations: getNewCommemorations,
        getDeathNoticeCondolences: getDeathNoticeCondolences,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(DeathNoticeDetails);
