import React from 'react'
import Fade from '../../helpers/fade'

import styler from '../../helpers/styler'
import Head from '../Layout/Head'
import {bindActionCreators} from 'redux'
import {authAction, logoutAction} from '../../actions'
import Api from '../../helpers/api'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {cdn} from '../../helpers/config'
import moment from 'moment'
import Paginator from '../../helpers/paginator'
import history from "../../helpers/history";
import {initialize} from "../../helpers/agf";

class Profile extends React.Component {

  state = {
    subscriptions: {data: []},
    condolences: {data: []}
  }

  doLogout = (event) => {
    //event.preventDefault();
    this.props.logoutAction();
  }

  componentDidMount() {

    if (this.props.user === null || this.props.user.id === null) {
      history.push('/login')
    }
    if (localStorage.getItem('id') === 'undefined' || localStorage.getItem('id') === null
        || localStorage.getItem('id') === 'NaN' || localStorage.getItem('id') === '0'
        || localStorage.getItem('id') === ''){
      this.doLogout()
    }
    this.getSubscriptions()
    this.getCondolences()
    initialize('_profile', 'profile');
  }

  getSubscriptions = () => {
    Api.post('user/get-subscriptions', {
      user_id: this.props.user.id
    }).then((response) => {
          if (response.data.success) {
            this.setState({
              subscriptions: response.data.subscriptions,
            })
          } else {
            console.error(response.data.message)
          }
        }
    )
  }

  getCondolences = (pageNumber) => {
    if (typeof pageNumber === 'undefined') pageNumber = 1

    Api.post('user/get-condolences?page=' + pageNumber, {
      user_id: this.props.user.id
    }).then((response) => {
          if (response.data.success) {
            this.setState({
              condolences: response.data.condolences,
            })
          } else {
            console.error(response.data.message)
          }
        }
    )
  }

  render() {
    const canonicalUrl = window.location.href;

    return (
        <Fade>
          <Head title="PNP Trauerportal - Mein Profil" canonicalUrl={canonicalUrl} />
          <div className={styler(["uk-container", "uk-padding"])}>
            <div className={styler(["divider"])}>
              <h2>Mein&nbsp;Profil</h2>
            </div>

            <div className={styler(['uk-margin-medium-bottom'])}>
              <h3>Meine Abonnements</h3>
              <ul className={styler(['uk-list', 'uk-list-striped'])}>
                {this.state.subscriptions.data.map(subscription => {
                  return (
                      <li key={subscription.id}>
                        <Link to={'/traueranzeige/' + subscription.trauerfall.vname + '-' + subscription.trauerfall.nname + '/' + subscription.trauerfall.id} title={subscription.trauerfall.vname + ' ' + subscription.trauerfall.nname + ' - Traueranzeige'}  className="uk-flex">
                          <figure className="uk-margin-small-right" style={{marginBottom: 0}}>
                            <img
                                src={cdn + subscription.trauerfall.id + '&width=150'}
                                title={subscription.trauerfall.vname + ' ' + subscription.trauerfall.nname + ' - Traueranzeige'}
                                alt={subscription.trauerfall.vname + ' ' + subscription.trauerfall.nname + ' - Traueranzeige'}
                            />
                          </figure>
                          {subscription.trauerfall.vname} {subscription.trauerfall.nname}
                        </Link>
                      </li>
                  )
                })}
              </ul>
              <Paginator itemsPerPage={10} totalItems={this.state.subscriptions.total || 0} onPageChange={this.getSubscriptions.bind(this)} pageRangeDisplayed={3}/>

            </div>

            <div>
              <h3>Meine Kondolenzeinträge</h3>
              <ul className={styler(['uk-list', 'uk-list-striped'])}>
                {this.state.condolences.data.map(condolence => {
                  return (
                      <li key={condolence.id}>
                        <Link to={'/traueranzeige/' + condolence.death_notice.vname + '-' + condolence.death_notice.nname + '/' + condolence.death_notice.id} title={condolence.death_notice.vname + ' ' + condolence.death_notice.nname + ' - Traueranzeige'}>
                          für {condolence.death_notice.vname} {condolence.death_notice.nname}, verfasst am {moment(condolence.create_time).format('L')}
                          {condolence.status === 1 && <span className={styler(['uk-text-small', 'uk-text-dark'])}> (noch nicht freigegeben)</span>

                          }
                        </Link>
                      </li>
                  )
                })}
              </ul>
              <Paginator itemsPerPage={10} totalItems={this.state.condolences.total || 0} onPageChange={this.getCondolences.bind(this)} pageRangeDisplayed={3}/>


            </div>
          </div>

        </Fade>
    )
  }
}


function

mapStateToProps(state) {
  return {
    user: state.user
  }
}

function

matchDispatchToProps(dispatch) {
  return bindActionCreators({
    authAction: authAction,
    logoutAction: logoutAction,
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(
    Profile
);
