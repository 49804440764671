import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LoginForm from './LoginForm';
import {connect} from 'react-redux';
import Fade from '../../helpers/fade';

import styler from '../../helpers/styler';
import IVWPixel from "../../helpers/ivw";
import Head from "../Layout/Head";
import {initialize} from "../../helpers/agf";

/**
 * Login component
 *
 * @class Login
 * @author Alexander Jungwirth
 */
class Login extends Component {

    state = {
        user: '',
        password: '',
        remember: false,
        bee: '',
        loading: false,
        message: {
            active: false
        }
    };

    componentDidMount() {
        IVWPixel.pageLoad();
        initialize('_login', 'login');
    }

    /**
     * render method
     *
     * @returns {*}
     */
    render() {
        const canonicalUrl = window.location.href;

        return (
            <Fade>
                <Head title="PNP Trauerportal - Login" canonicalUrl={canonicalUrl} />
                <div className={styler(["uk-container", "uk-padding"])}>
                    <div className={styler(["divider"])}>
                        <h2>Login</h2>
                    </div>
                    {this.props.user.hasOwnProperty('id') && this.props.user.id !== null ? (
                        <Fade>
                            <div className={styler(["uk-text-center", "uk-margin-medium-top", "uk-margin-medium-bottom"])}>
                                <p>Hallo {this.props.user.salutation} {this.props.user.firstname} {this.props.user.lastname}, Sie haben sich erfolgreich eingeloggt.</p>
                                <p>Sie können jetzt unbegrenzte Kondolenzeinträge verfassen, diese verändern und löschen, und Kondolenzbücher abonnieren.</p>
                            </div>
                        </Fade>

                    ) : (
                        <div>
                            <p>Um sich auf dem Trauerportal der <span className={styler(["uk-text-bold"])}>Passauer Neuen Presse</span> einzuloggen, benötigen Sie ein aktives Benutzerkonto auf pnp.de. Wenn Sie noch kein entsprechendes
                                Konto haben, können Sie sich <Link
                                    to={'/register'}>hier</Link> registrieren. Dieses Konto können Sie dann für alle unsere Portal nutzen.</p>
                            <LoginForm visible={true} {...this.props} />
                        </div>
                    )
                    }
                </div>
            </Fade>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Login);
